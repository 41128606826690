import React from 'react';
import '../../styles/ImitationLearning.css';

const projects = [
  {
    title: 'Imitation Learning',
    description:
      "This project focus on imitation learning by Robomimic framework with Franka Emika Panda",
    images: ['/task_lift.gif','/tool_hang.gif'] // Add video URL here
  },
  {
    title: 'Robosuite',
    description:
      'robosuite is a simulation framework powered by the MuJoCo physics engine for robot learning. It also offers a suite of benchmark environments for reproducible research.',
    images: [
      '/robosuite.gif', // CHAMP
    ],
  },
  {
    title: 'Robomimic',
    description:
      'robomimic is a framework for robot learning from demonstration. It offers a broad set of demonstration datasets collected on robot manipulation domains and offline learning algorithms to learn from these datasets.',
    video: 'https://www.youtube.com/embed/cvpNAr1z9-w',
    image: '/CHAMP_structure.png',
  },
];

const RoboticVision = () => {
  return (
    <div className="project-container">
      {projects.map((project, index) => (
        <Section
          key={index}
          title={project.title}
          description={project.description}
          mainImage={project.mainImage}
          gallery={project.gallery}
          video={project.video} // Single video
          videos={project.videos} // Multiple videos
          images={project.images} 
        />
      ))}
    </div>
  );
};

const Section = ({ title, description, mainImage, gallery, video, videos, images }) => (
  <section className="section-container">
    <div className="content-header">
      <h2>{title}</h2>
      {/* Split description into paragraphs */}
      {description.split('\n\n').map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>

    {/* Image Above Video */}
    {title === 'Control Block' && (
      <div className="image-above-video">
        <img src="/CHAMP_structure.png" alt="Gait Parameters" className="gait-image" />
      </div>
    )}

    {/* Single Video */}
    {video && (
      <div className="video-container">
        <iframe
          width="100%"
          height="400"
          src={video}
          title={`${title} Video`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    )}

    {/*Two GIFS for human-robot Interactio*/}
    {images && (
      <div className="gif-container">
        {images.map((image, index) => (
          <img key={index} src={image} alt={`gif-${index}`} className="gif" />
        ))}
      </div>
    )}

    {/* Two Videos for VSLAM */}
    {videos && (
      <div className="videos-container">
        {videos.map((videoUrl, index) => (
          <iframe
            key={index}
            className="responsive-iframe"
            src={videoUrl}
            title={`${title} Video ${index + 1}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ))}
      </div>
    )}

    {mainImage && (
      <div className="main-image-container">
        <img src={mainImage.src} alt={mainImage.alt} className="main-image" />
      </div>
    )}

    {gallery && (
      <div className="gallery-container">
        {gallery.map((item, index) => (
          <img key={index} src={item.src} alt={item.alt} className="gallery-image" />
        ))}
      </div>
    )}
  </section>
);

export default RoboticVision;
